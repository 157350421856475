import React from "react"
import Head from "../components/Head"
import Container from "../components/Container.js"
import HeaderTitle from "../components/HeaderTitle.js"
import { Link } from "gatsby"
import TableOfContents from "../components/TableOfContents"
import TableOfContentsHeader from "../components/TableOfContents/Header"
import TableOfContentsRow from "../components/TableOfContents/Row"
import TableOfContentsSection from "../components/TableOfContents/Section"
import TextSectionLink from "../components/TextContainer/Link"

export default function InterviewTableOfContents() {
  return (
    <>
      <Head />
      <Container lessVerticalSpace>
        <HeaderTitle
          title={<Link to="/#interviews">JavaScript Simplified</Link>}
          subtitle="Tips For Landing A Job"
        />
      </Container>
      <TableOfContents>
        <TableOfContentsSection>
          <TableOfContentsHeader>Interviews</TableOfContentsHeader>
          <TableOfContentsRow
            duration="52:40"
            title="How To Land Your First Job With Dorian From Dorian Develops"
          />
          <TableOfContentsRow
            duration="43:56"
            title="Motivational Advice From Danny Thompson"
          />
          <TableOfContentsRow
            duration="46:28"
            title="How To Get Started In Freelance With Eddie Jaoude"
          />
          <TableOfContentsRow
            duration="34:49"
            title="How To Ace Your Interview With Jessica From Coder Coder"
          />
        </TableOfContentsSection>
        <div className="mt-12">
          <div className="mb-3">
            <TextSectionLink local href="/beginner-table-of-contents">
              Beginner Course Video List
            </TextSectionLink>
          </div>
          <div className="mb-3">
            <TextSectionLink local href="/advanced-table-of-contents">
              Advanced Course Video List
            </TextSectionLink>
          </div>
          <div>
            <TextSectionLink local href="/bonus-projects-table-of-contents">
              Bonus Projects Video List
            </TextSectionLink>
          </div>
        </div>
      </TableOfContents>
      <div className="my-12 text-2xl text-center">
        <TextSectionLink local href="/#interviews">
          Back To Landing Page
        </TextSectionLink>
      </div>
    </>
  )
}
